import { useEffect, useState } from "react";
import Lottie from "react-lottie";
import animationData from "../src/assets/lotties/banner.json";
import NewCardComponent from "./components/NewCardComponent";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { motion } from "framer-motion";
import NewModal from "./components/NewModal";

import bellIcon from ".//assets/bell_icon.svg";

const NewHome = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [hideBanner, setHiddeBanner] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [selectedGame, setSelectedGame] = useState(null);

  const onModalClose = () => {
    setSelectedGame(null);
    setShowModal(false);
  };

  let navigation = useNavigate();
  const location = useLocation();

  let games = [];

  if (location.state == null) {
  } else {
    games = location.state.games;
  }

  const onTextChanged = (e) => {
    setSearchQuery(e.target.value);
    console.log(e.target.value);
    if (e.target.value.length > 0) {
      if (!hideBanner) {
        setHiddeBanner(true);
      }
    } else if (e.target.value.length == 0) {
      if (hideBanner) {
        console.log("show");
        setHiddeBanner(false);
      }
    }
  };

  const filterGames = games.filter((game) => {
    return (
      game.title
        .toLowerCase()
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) !== -1
    );
  });

  var tapMAx = 1;
  function enableCookies() {
    tapMAx = tapMAx + 1;
    if (tapMAx > 5) {
      document.cookie = `custom_cookie=custom`;
    }
  }

  const onGameSelected = (game) => {
    console.log(game);
    setSelectedGame(game);
    setShowModal(true);
    //let path = "details";
    //navigation(path, { state: { game: game } });
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (games.length == 0) {
      navigation("/");
    }
  });

  return (
    <>
      <div className="flex flex-col bg-slate-50 ">
        <div
          id="header_wrapper"
          className="flex flex-row justify-between mx-8 mt-8"
        >
          <div
            id="account_round"
            className="h-4 w-4 bg-green-400 rounded-xl"
            onClick={enableCookies}
          ></div>
          <img src={bellIcon} width="25" height="25" />
        </div>

        <div
          id="search"
          class="relative flex items-center mx-8 mt-6  h-12 rounded-2xl shadow-lg bg-white overflow-hidden"
        >
          <div class="grid place-items-center h-full w-12 text-gray-300">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>

          <input
            class="peer h-full w-full outline-none font-medium text-sm text-gray-700 pr-2"
            type="text"
            id="search"
            onChange={onTextChanged}
            placeholder="Search game/app/tweek"
          />
        </div>

        <motion.div
          animate={{
            height: !hideBanner ? "auto" : 0,
            opacity: !hideBanner ? 1 : 0,
            scale: !hideBanner ? 1 : 0,
          }}
        >
          <div
            id="banner"
            className="h-40 bg-yellow-400 mx-8 mt-10 rounded-2xl flex flex-row "
          >
            <h1 className="text-xl font-bold my-auto mx-6">
              Let's download a game and enjoy
            </h1>
            <div className="">
              <Lottie options={defaultOptions} height={160} width={170} />
            </div>
          </div>
        </motion.div>

        <h2 id="popular_title" className="mx-8 mt-8 text-xl font-bold">
          Popular
        </h2>
        <div className="mx-6 mt-2">
          <div class="flex overflow-x-scroll pb-10 hide-scroll-bar mt-3">
            <div class="flex flex-nowrap ">
              {filterGames.length == 0 ? (
                <h1 className="ml-8 text-m font-bold">No Game Found</h1>
              ) : null}
              {filterGames.map(function (item, idx) {
                return (
                  <NewCardComponent
                    key={idx}
                    game={item}
                    callback={onGameSelected}
                  ></NewCardComponent>
                );
              })}
            </div>
          </div>
        </div>
        <h2 id="popular_title" className="mx-8 text-xl font-bold">
          Top Rated
        </h2>
        <div className="mx-6 mt-2">
          <div class="flex overflow-x-scroll pb-10 hide-scroll-bar mt-3">
            <div class="flex flex-nowrap ">
              {games
                .filter((item) => parseFloat(item.rating) > 4.3)
                .map(function (item, idx) {
                  return (
                    <NewCardComponent
                      key={idx}
                      game={item}
                      callback={onGameSelected}
                    ></NewCardComponent>
                  );
                })}
            </div>
          </div>
        </div>
        {showModal ? (
          <NewModal
            visible={showModal}
            onClose={onModalClose}
            game={selectedGame}
          ></NewModal>
        ) : null}
      </div>
    </>
  );
};

export default NewHome;
