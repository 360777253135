import BackIcon from "../assets/back.svg";
import ShareIcon from "../assets/share.svg";
import IosIcon from "../assets/apple.svg";
import AndroidIcon from "../assets/android.svg";
import DownloadIcon from "../assets/download_icon.svg";
import NewModal from "./NewModal";
import { useState } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

const NewDetailsComponent = (props) => {
  const [showModal, setShowModal] = useState(false);

  const location = useLocation();
  let navigate = useNavigate();

  const onItemClicked = () => {
    setShowModal(true);
  };

  const onModalClose = () => {
    setShowModal(false);
  };

  let game = location.state.game;

  return (
    <>
      <div className="flex flex-col bg-slate-50 ">
        <div
          id="header_wrapper"
          className="flex flex-row justify-between mx-8 mt-8"
        >
          <img
            src={BackIcon}
            width="30"
            height="30"
            onClick={() => navigate(-1)}
          />
          <img src={ShareIcon} width="25" height="25" />
        </div>

        <img
          className="h-64 bg-yellow-200 mx-8 rounded-3xl mt-4"
          src={"https://mobgames.online/images/" + game.iconPath}
        />
        <h1 className="text-xl font-bold break-words mx-8 mt-2">
          {game.title}
        </h1>

        <p className="mx-8 text-xs break-words text-purple-600">
          {game.author}
        </p>
        <div className="">
          <p className="mx-8 text-m break-words">{game.description}</p>
        </div>
        <div className="flex flex-row justify-between mx-8 mt-4">
          <div className="flex flex-row border-2 rounded-full pl-4 pr-4 pt-1 pb-1 border-black">
            <img src={IosIcon} width="20" height="20" />
            <img src={AndroidIcon} width="18" height="18" className="ml-4" />
          </div>
          <h1 className="font-bold text-m my-auto">
            {game.download + " downloads"}
          </h1>
        </div>
        <div
          onClick={onItemClicked}
          className="flex flex-row justify-between mx-8 mt-4 pt-4 pb-4 pl-6 pr-6 bg-purple-600 rounded-full"
        >
          <h1 className="font-bold text-xl text-white">Install</h1>

          <img src={DownloadIcon} width="28" height="28" />
        </div>
      </div>
      {showModal ? (
        <NewModal
          visible={showModal}
          onClose={onModalClose}
          game={game}
        ></NewModal>
      ) : null}
    </>
  );
};

export default NewDetailsComponent;
