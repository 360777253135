export const TEST_GET_GAMES_ENDPOINT =
  "http://localhost/landing/getDataTest.php";
export const GET_GAMES_ENDPOINT = "https://mobgames.online/getData.php";

export const LOGIN_ENDPOINT = "https://mobgames.online/login_auth_test.php";
export const TEST_LOGIN_ENDPOINT =
  "http://localhost/landing/login_auth_test_test.php";

export const ADD_GAME_ENPOINT = "https://mobgames.online/insert_game_data.php";
export const TEST_ADD_GAME_ENPOINT =
  "http://localhost/landing/insert_game_data_test.php";

export const IMAGE_UPLOAD_ENDPOINT = "https://mobgames.online/upload.php";
export const TEST_IMAGE_UPLOAD_ENDPOINT =
  "http://localhost/landing/upload_test.php";

export function GET_GAMES_URL() {
  if (useDev) {
    return TEST_GET_GAMES_ENDPOINT;
  } else {
    return GET_GAMES_ENDPOINT;
  }
}

export let GET_LOGIN_URL = () => {
  if (useDev) {
    return TEST_LOGIN_ENDPOINT;
  } else {
    return LOGIN_ENDPOINT;
  }
};

export let GET_IMG_URL = () => {
  if (useDev) {
    return TEST_IMAGE_UPLOAD_ENDPOINT;
  } else {
    return IMAGE_UPLOAD_ENDPOINT;
  }
};

export let GET_ADD_GAME_URL = () => {
  if (useDev) {
    return TEST_ADD_GAME_ENPOINT;
  } else {
    return ADD_GAME_ENPOINT;
  }
};

const useDev = false;
