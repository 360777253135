import axios from "axios";
import { useLocation, Link, useNavigate, Navigate } from "react-router-dom";
import { GET_LOGIN_URL } from "./Constants";

const Login = (props) => {
  const navigate = useNavigate();
  const n = useNavigate();
  const location = useLocation();
  const state = location.state;

  const handleSubmit = (event) => {
    console.log(event);
    event.preventDefault();

    var { uname, pass } = document.forms[0];

    const sendData = {
      username: uname.value,
      password: pass.value,
    };

    console.log(sendData);

    axios.post(GET_LOGIN_URL(), sendData).then((result) => {
      console.log(result.data);
      if (result.data == true) {
        console.log("Success");

        n("/manage", { state: { isAuth: true } });
      } else {
        console.log("Error login");
      }
    });
  };

  return (
    <div className="form" onSubmit={handleSubmit}>
      <form>
        <div className="input-container">
          <label>Username </label>
          <input type="text" name="uname" required />
        </div>
        <div className="input-container">
          <label>Password </label>
          <input type="password" name="pass" required />
        </div>
        <div className="button-container">
          <input type="submit" />
        </div>
      </form>
    </div>
  );
};

export default Login;
